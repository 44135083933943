/* === GLOBAL SETTINGS === */
$background-color: #F7F7F7;
$text-color: #242424;
$text-color-muted: #626262;
$tint-color: #2A9471;
$tint-color-muted: #E6F7F1;

@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

* {
  box-sizing: border-box;
}

body {
  background: $background-color;
  color: $text-color;
  font-size: 16px;
}

h1 {
  font-size: 42px;
  font-weight: 700;
  margin: 0 0 8px 0;
}

h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 16px 0;
}

h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-weight: 500;
  margin: 0 0 4px;
}

p {
  color: $text-color-muted;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  max-width: 600px;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid;
  border-color: $tint-color;
  padding-bottom: 1px;
  transition: padding-bottom 0.3s ease, border-color 0.3s ease, color 0.3s ease;

  &:hover {
    cursor: pointer;
    color: $text-color;
    padding-bottom: 2px;
    border-color: $text-color;
  }
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
  display: block;
}

button {
  display: inline-block;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:active {
    color: inherit;
  }
}

.power-links {
  display: block;
  border: none;
  color: $tint-color;
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
  padding-bottom: 0;

  &:hover {
    padding-bottom: 0;
  }
}

.container {
  width: 95%;
  max-width: 900px;
  margin: auto;
}

.section,
footer {
  margin-top: 104px;
}

.card {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.07);
  border-radius: 8px;
}

.emoji-wrapper {
  position: relative;
  display: block;
  font-size: 40px;
  height: 64px;
  width: 100%;
  padding: 9px 0 2px;
  text-align: center;
  border-radius: 6px;
  background: $tint-color-muted;

  a {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.milestone p,
.project p {
  font-size: 14px;
  color: $text-color-muted;
}

/* === Navigation bar === */
nav {
  margin-top: 16px;
  padding: 8px 16px;
  text-align: center;

  button,
  li a {
    border: none;
    width: 100%;
    padding: 8px 0;
    // margin: 8px 0;
    border-radius: 6px;
    background: white;
    font-weight: 600;
    transition: background-color .3s ease;

    &:hover,
    &.active {
      padding-bottom: 8px;
      background-color: $tint-color-muted;
    }
  }

  .navigation-links {
    display: none;
  }

  .expanded.navigation-links {
    display: block;
  }

  @media (min-width: 601px) {
    text-align: left;

    #hamburger {
      display: none;
    }

    .navigation-links {
      display: inline-block;
    }

    li {
      display: inline-block;
      margin-right: 8px;
    }

    li a {
      display: inline-block;
      width: auto;
      padding: 8px;
    }
  }
}

/* === About me === */

.section-about {
  p {
    margin-bottom: 24px;
  }

  h3 {
    margin: 64px auto 40px;
  }
}

.life-events {
  position: relative;

  @media (min-width: 720px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.milestone {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .emoji-wrapper {
    width: 64px;
    border-radius: 50%;
    margin: 0 16px 0 0;

    @media (min-width: 720px) {
      margin: 0 0 8px 0;
    }
  }

  .text-wrapper {
    text-align: left;

    @media (min-width: 720px) {
      text-align: center;
    }
  }

  p {
    margin-bottom: 0;
  }

  @media (min-width: 720px) {
    flex-direction: column;
  }
}

.time-line {
  display: block;
  position: absolute;
  width: 4px;
  height: 100%;
  left: 30px;
  background: $tint-color-muted;

  @media (min-width: 720px) {
    width: calc(100% - 90px);
    height: 4px;
    left: 45px;
    top: 30px;
  }
}

/* === Projects === */

.projects-wrapper {
  margin-top: 40px;

  @media (min-width: 462px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 16px;
    grid-row-gap: 24px;
  }

  @media (min-width: 740px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.project {
  margin-bottom: 40px;

  .emoji-wrapper {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }
}

/* === Contact === */

.section-social ul {
  display: flex;
  margin: 16px auto 40px;

  li a {
    margin-right: 16px;
    border: none;
    padding: 0;
    transform: scale(1);
    transition: transform .3s ease;

    &:hover {
      padding: 0;
      transform: scale(1.1);
    }
  }
}

.box {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 62px;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.front {
  position: absolute;
  width: 100%;
  text-align: center;

  button {
    @extend h3;
    padding: 16px;
    width: 100%;
  }
}

.back {
  position: absolute;
  width: 100%;
  padding: 24px;
}

.label-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    @extend p;
    color: $text-color;
    font-weight: 400;
    margin-bottom: 4px;
  }

  input,
  textarea {
    padding: 10px 16px;
    border-radius: 6px;
    border: 2px solid #E3E3E3;

    &:hover,
    &:focus {
      border-color: $tint-color;
      outline: none;
    }
  }

  textarea {
    min-height: 164px;
  }
}

#submit {
  width: 100%;
  padding: 10px 16px;
  background: $tint-color-muted;
  font-weight: 600;
  border-radius: 6px;
}

.back {
  transform: rotateX(-90deg) translateZ(-140px) translateY(200px);
}

/* === Footer === */
footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: $text-color-muted;
  margin-bottom: 104px;
  padding-top: 16px;
  border-top: 2px solid #E6E6E6;

  a {
    margin-top: 16px;
    border: none;
    text-decoration: underline;
    padding: 0;

    &:hover {
      padding: 0;
    }
  }

  @media (min-width: 628px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

/* === Submit success === */

#giphy {
  background-image: url("../images/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
